<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div class="w-100">
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <template v-if="typeof text === 'object'">
            <ul
              v-for="txt in text"
              :key="txt.key"
              :class="`text-${variant} m-0 p-0`"
              style="list-style-position: outside; vertical-align: top;"
            >
              <li
                style="margin:0; padding:0; vertical-align: top;"
              >
                <small
                  class="text-body"
                  style="width: 35ch;"
                  v-text="txt"
                />
              </li>
            </ul>
          </template>
          <template v-else>
            <small
              v-if="text"
              class="d-inline-block text-body"
              v-text="text"
            />
          </template>
          <b-progress
            v-if="progress !== null"
            :id="`${progressId}`"
            class="block mt-1 w-100"
            :variant="variant"
            :value="progress"
          />
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: [String, Array, Object],
      default: null,
    },
    progress: {
      type: Number,
      default: null,
    },
    progressId: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
